import React from "react";
import styles from "./Footer.module.css";
import { ReactComponent as InstagramLogo } from "../../assets/images/social-instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/images/social-facebook.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/images/social-linkedin.svg";
import {
  contact,
  social,
} from "../../assets/links";
import Section from "../layout/Section";

function Footer() {
  return (
    <Section wrapperTag="footer">
      <nav className={styles.social}>
        <ul>
          <li>
            <a href={social.facebook}>
              <FacebookLogo />
            </a>
          </li>
          <li>
            <a href={social.linkedin}>
              <LinkedInLogo />
            </a>
          </li>
          <li>
            <a href={social.instagram}>
              <InstagramLogo />
            </a>
          </li>
        </ul>
      </nav>
      <div className={styles.contact}>
        <p>{contact.address.street}, {contact.address.suburb}, {contact.address.state} {contact.address.postcode}
        </p>
        <ul>
          {
            contact.contacts.map((person) => (
              <li key={`contact-${person.name}`}>
                <h3>{person.name}</h3>
                <p>{person.phone}<br/><a href={`mailto:${person.email}`}>{person.email}</a></p>
              </li>
            ))
          }
        </ul>
      </div>
    </Section>
  );
}

export default Footer;
