import React from "react";
import styles from "./Banner.module.css";
import Section from "../../layout/Section";
import Logo from "../../system/Logo.jsx"

function Banner() {
  return (
    <Section wrapperClassName={styles.banner}>
      <Logo />
      <div>
        <p>WEBSITE UNDER CONSTRUCTION</p>
      </div>
    </Section>
  );
}

export default Banner;
