const contact = {
    address: {
        street: "6-8 Montrose Street",
        suburb: "Hawthorn East",
        postcode: "3123",
        state: "Victoria"
    },
    contacts: [
        {
            name: "Michael",
            email: "michael@meritprojects.com.au",
            phone: "0404 257 427"
        },
        {
            name: "Evan",
            email: "evan@meritprojects.com.au",
            phone: "0409 561 646"
        }
    ]
}

const social = {
	facebook: "https://www.facebook.com/Merit-Projects-Vic-Pty-Ltd-106361201958691",
	instagram: "https://www.instagram.com/merit_projects/",
	linkedin: "https://www.linkedin.com/company/merit-projects-vic-pty-ltd/"
}

export {
	contact,
	social
}