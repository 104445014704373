import React from "react";
import styles from "./Section.module.css";
import cx from "classnames";

function Section({
  wrapperTag: OutsideTag = "section",
  tag: InsideTag = "div",
  children,
  wrapperClassName,
  className,
}) {
  return (
    <OutsideTag className={wrapperClassName}>
      <InsideTag className={cx([styles.wrap, className])}>{children}</InsideTag>
    </OutsideTag>
  );
}

export default Section;
