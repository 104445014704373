import React from 'react';
import LogoWhite from '../../assets/images/logo-white.svg';
import styles from './Logo.module.css';

function Logo() {
	return (
		<img src={LogoWhite} className={styles.logo} alt="Merit Projects" />
	);
}

export default Logo;