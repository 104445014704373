import Banner from './components/view/banner/Banner.jsx';
import Footer from './components/footer/Footer.jsx';

import './App.css';

function App() {
    return (
        <>
            <main>
                <Banner />
            </main>
            <Footer />
        </>
    );
}

export default App;
